import { Box } from '@mui/material';
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect } from 'react';

import { Permissions } from "@/helpers/permissions";
import { useUser, useLoader } from '@/hooks';
import AuthorizedRoute from '@/routes/AuthorizedRoute';
import { useLazyGetAllUsersQuery } from '@/service';

import { colDefs } from "./UsersList.map";
import { boxContainerSx } from './UsersList.styles';
import UsersListToolbar from "./UsersListToolbar";

const UsersList: FC = () => {
  const apiRef = useGridApiRef();

  const [getAllUsers, { isLoading }] = useLazyGetAllUsersQuery();
  const { usersSt } = useUser();
  const { setLoader } = useLoader();


  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <AuthorizedRoute allowedPermission={Permissions.LIST_USERS}>
      <Box sx={boxContainerSx} data-testid="users-list-component">
        <DataGrid
          apiRef={apiRef}
          columns={colDefs}
          rows={usersSt ?? []}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          loading={isLoading}
          sx={{ borderWidth: 0 }}
          slots={{
            toolbar: UsersListToolbar,
          }}
          localeText={{
            filterOperatorContains: "Contiene",
            filterOperatorEquals: "Igual",
            filterOperatorStartsWith: "Comienza con",
            filterOperatorEndsWith: "Termina con",
            filterOperatorIsEmpty: "Está vacío",
            filterOperatorIsNotEmpty: "No esta vacío",
            filterOperatorIsAnyOf: "Es cualquiera de",
            filterPanelOperator: "Criterio",
            filterPanelColumns: "Columnas",
            filterPanelInputLabel: "Valor",
            filterPanelInputPlaceholder: "Ingresa un valor",
            columnMenuSortAsc: "Ordenar ASC",
            columnMenuSortDesc: "Ordenar DESC",
            columnMenuHideColumn: "Ocultar columna",
            columnMenuManageColumns: "Gestionar columnas",
            columnMenuFilter: "Filtrar",
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
        />
      </Box>
    </AuthorizedRoute>
  );
};

export default UsersList;