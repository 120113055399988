import { SxProps, Theme } from "@mui/material";

export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  display: 'grid',
  borderRadius: '10px',
  width: '100%',
});

export const dataGridSx: SxProps<Theme> = (theme) => ({
  borderWidth: 0,
  p: theme.spacing(2)
});