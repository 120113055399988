import { ArrowBack, HomeOutlined, EditOutlined, PeopleOutline } from '@mui/icons-material';
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Link,
  Breadcrumbs,
  Alert
} from '@mui/material';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { Permissions } from "@/helpers/permissions";
import { useRole, useMessager, useLoader } from '@/hooks';
import AuthorizedRoute from '@/routes/AuthorizedRoute';
import { useLazyGetAllRolesQuery, useLazyGetUserByIdQuery, useUpdateUserMutation } from '@/service';
import { IRole } from '@/state/Role/slice.types';
import { IUser } from '@/state/User/slice.types';

import { initialValues, updateValidationSchema } from './UserForm.schema';
import { boxContaineSx, titleSx, backButtonSx, StyledBreadcrumb } from './UserForm.styles';

const UpdateUserForm: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState<IUser | undefined>();
  const [getAllRoles, { isLoading }] = useLazyGetAllRolesQuery();
  const [updateUser, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateUserMutation();
  const [getUserById, { data, isLoading: isLoadingUser }] = useLazyGetUserByIdQuery();

  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const { rolesSt } = useRole();

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (id) {
      getUserById(id)
    }
  }, [id]);

  useEffect(() => {
    setLoader(isLoadingUser);
    if (data) {
      setUser({
        ...data.data,
        role_id: data.data.role.id
      } as IUser);
    }
  }, [data, isLoadingUser]);

  const handleOnClose = () => {
    navigate('/dashboard/v1/admin/manage-users/list');
  };

  useEffect(() => {
    setLoader(isUpdating);
    if (isUpdated && !isUpdating) {
      setMessage({
        title: 'Usuario actualizado con éxito',
        body: (
          <Alert severity="success">
            Se ha actualizado el usuario con éxito.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      })
    }
  }, [isUpdating, isUpdated]);

  const goBack = () => {
    navigate(-1)
  };

  const onSubmit = (values: IUser) => {
    updateUser(values);
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.UPDATE_USER}>
      <Box sx={boxContaineSx}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                aria-label="go back"
                sx={backButtonSx}
                onClick={goBack}
              >
                <ArrowBack />
              </IconButton>
              <Link component={NavLink} to="/dashboard/v1/admin" variant="subtitle1">Volver atrás</Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body1" sx={{ mr: 2 }}>Te encuentras aquí:</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  label="Gestión de usuarios"
                  icon={<HomeOutlined fontSize="small" />}
                  disabled
                />
                <StyledBreadcrumb
                label="Todos los usuarios"
                icon={<PeopleOutline fontSize="small" />}
                disabled
              />
                <StyledBreadcrumb
                  label="Editar usuario"
                  icon={<EditOutlined fontSize="small" />}
                />
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Typography variant="h5" fontWeight="bold" sx={titleSx}>Editar usuario</Typography>
            <Formik
              initialValues={(id && user) ? user : initialValues}
              validationSchema={updateValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
              validateOnChange={false}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="rfc"
                        name="rfc"
                        value={values.rfc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="RFC"
                        placeholder="Escribe..."
                        variant="outlined"
                        error={Boolean(touched.rfc && errors.rfc)}
                        helperText={Boolean(touched.rfc && errors.rfc) && errors.rfc}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="first_name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Nombres"
                        placeholder="Escribe..."
                        variant="outlined"
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={Boolean(touched.first_name && errors.first_name) && errors.first_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="last_name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Apellido paterno"
                        placeholder="Escribe..."
                        variant="outlined"
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={Boolean(touched.last_name && errors.last_name) && errors.last_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="mother_last_name"
                        name="mother_last_name"
                        value={values.mother_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Apellido materno"
                        placeholder="Escribe..."
                        variant="outlined"
                        error={Boolean(touched.mother_last_name && errors.mother_last_name)}
                        helperText={Boolean(touched.mother_last_name && errors.mother_last_name) && errors.mother_last_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="email"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Correo electrónico"
                        placeholder="Escribe..."
                        variant="outlined"
                        error={Boolean(touched.email && errors.email)}
                        helperText={Boolean(touched.email && errors.email) && errors.email}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Teléfono"
                        placeholder="Escribe..."
                        variant="outlined"
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={Boolean(touched.phone && errors.phone) && errors.phone}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="role-label">Rol</InputLabel>
                        <Select
                          labelId="role-label"
                          id="role_id"
                          name="role_id"
                          value={values.role_id}
                          onChange={handleChange}
                          label="Rol"
                          disabled={isLoading}
                        >
                          <MenuItem value={0} disabled>Seleccione...</MenuItem>
                          {rolesSt.map(({ id, name }: IRole) => (
                            <MenuItem value={id} key={`role-${id}`}>{name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading || isLoadingUser || isUpdating || !dirty || !isValid}
                      >
                        Actualizar usuario
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default UpdateUserForm;